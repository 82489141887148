(function(d){
    ebates.optim = {
        page: {other: "other", home: "home-page", intl: "interstitial", extl: "external"},
        is_page_type_found: false,
        is_sign_up_page: false,
        page_types: {
            "home-page": /^(\/)*($|index\.(htm|do|jsp)$)/i,
            "store-page": function () {
                return /^\/(coupons\/.+\/index\.(htm|do)|ebay|amazon\.htm|livingsocial\.htm|groupon\.htm|.+\.com)/i.test(location.pathname) || ebates.param.get('merchant_id');
            },
            "all-stores-page": /^\/stores\/all\//i,
            "interstitial": /^\/(signUp|editUserInfos|auth\/getLogonForm)\.do/i,
            'coupons-page': /^\/coupons/i,
            'category-page': function () {
              return ebates && ebates.page && ebates.page.type === 'category';
            },
            'promo-page' : function () {
                return document.querySelector('#nav-tab8 a') && document.querySelector('#nav-tab8 a').getAttribute('href') === location.pathname;
            },
            'welcome-page': /^\/welcome(|.htm)$/i,
        },
        page_log: function(p){
            if(this.opt_track){ // todo unset '!'
                this.opt_log = typeof p === 'object' ? p : {set_page_type: p};
                for(var key in this.opt_log){
                    if(this.opt_log.hasOwnProperty(key)){
                        console.log(key + ": " + this.opt_log[key]);
                    }
                }
            }
            return false;
        },
        set_page_type: function(){
            if(ebates.user && ["FIRST_VISIT", "UNREGISTERED", "REGISTERED_NON_SHOPPER"].indexOf(ebates.user.status) > -1){
                for (var key in this.page_types) {
                    if(this.page_types.hasOwnProperty(key) && (typeof this.page_types[key] === 'object' && this.page_types[key].test(location.pathname)) || (typeof this.page_types[key] === 'function' && this.page_types[key]() )) {
                        if(key !== this.page.intl){
                            try {
                                window.localStorage.setItem('page_type', key);
                                this.page_log(key);
                                this.is_page_type_found = true;
                            } catch (e) {
                                return false;
                            }
                        }else if(/merchant_id\=[0-9]+/i.test(window.location)){
                            try {
                                this.origin_page = window.localStorage.getItem('page_type') || this.page.extl;
                                if(this.origin_page.indexOf("shop-link") < 0){
                                    window.localStorage.setItem('page_type', this.origin_page + " shop-link");
                                    window.localStorage.setItem('su_page_type', this.origin_page + " shop-link");
                                    this.output = this.origin_page + " shop-link";
                                }else{
                                    this.output = this.origin_page;
                                }
                                this.page_log(this.output);
                                this.is_page_type_found = true;
                            } catch (e) {
                                return false;
                            }
                        }else{
                            this.is_sign_up_page = true;
                        }
                    }
                }
                if(!this.is_page_type_found && !this.is_sign_up_page){
                    try {
                        window.localStorage.setItem('page_type', this.page.other);
                        this.page_log(this.page.other);
                    } catch (e) {
                        return false;
                    }
                }else if(!this.is_page_type_found && this.is_sign_up_page){
                    try {
                        window.localStorage.setItem('su_page_type', this.get_page_type());
                    } catch (e) {
                        return false;
                    }
                }
            }
        },
        set_auth_source: function(auth_source){
            try {
                window.localStorage.setItem('auth_source', auth_source);
            } catch (e) {
                return false;
            }
        },
        get_page_type: function(){
            return window.localStorage.getItem('su_page_type') || window.localStorage.getItem('page_type') || this.page.extl;
        },
        get_custom_su_event: function(){
            var ce_page_type = this.get_page_type(), ce_auth_source = window.localStorage.getItem('auth_source') || this.page.other;
            return (ce_page_type.indexOf(this.page.other) < 0) && ce_auth_source !== this.page.other ? ce_page_type + " " + ce_auth_source : this.page.other;
        },
        is_variation_without_overlay: function(experiment, variation) {
            return this.get_page_type() === this.page.home && window.optimizely && window.optimizely.get && window.optimizely.get("state") && window.optimizely.get("state").getVariationMap() && window.optimizely.get("state").getVariationMap()[experiment] && window.optimizely.get("state").getVariationMap()[experiment].id === variation ? true : false;
        },
        get_optimizely_script: function(default_snippet, custom_snippet_name) {
            var def_snippet = default_snippet || '8322291040.js';
            return custom_snippet_name ? '//cdn.optimizely.com/public/189334700/s/' + custom_snippet_name : '//cdn.optimizely.com/js/' + def_snippet;
        },
        append_script: function(script, d) {
            s = document.createElement('script');
            s.src=script;
            $.ajaxPrefilter(function( options, originalOptions, jqXHR ) {
                if ( options.dataType == 'script' || originalOptions.dataType == 'script' ) {
                    options.cache = true;
                }
            });
            $('#ebates_optimizely', d).after(s);
        },
        trigger_stats: function(experimentId, variationId){
            var optSeCookie = ebates.cookie.get('opt-se');
            if((!optSeCookie || $.inArray("" + experimentId, optSeCookie.split(",")) === -1)){
                var eventType = "Optimizely",
					entities = [{entityType: eventType, instanceId: "userId", dynamicEntityType: eventType, dynamicEntry: experimentId + "|" + variationId}],
				send_stats = function() {
					try {
						if(typeof experimentId !== "number" || typeof variationId !== "string") {
							throw "TypeError: experimentId is not a number or variationId is not a string";
						}else{
							$(d).trigger("stats", {
								data: ["send", "event", eventType, experimentId, variationId],
								map: {internal: eventType},
								entities: entities
							});
						}
					}
					catch(e) {
						console.error(e);
					}
                },
				cookie_string = function() {
                    const cArr = optSeCookie && optSeCookie.split(",") || [];
                    $.inArray(experimentId, cArr) === -1 && cArr.push(experimentId);
                    return cArr.join();
                };

                send_stats();
                ebates.cookie.set('opt-se', cookie_string());
            }
        },
        /*
            Use Amplitude Identify.append(property, value) method to set the user property, the user property name is 'optimizely'
            @param {string} experimentId  The experiment id of the optimizely experiment e.g: 14192690221
            @param {string} experimentName  The experiment name of the optimizely experiment e.g: 'MW | mWeb to App A/B Test: Variation 1 (on shopping)'
            @param {string} variationName  variation name of the optimizely experiment   e.g: Control
         */
        set_amplitude_stats: function (experimentId, experimentName, variationName) {
            var identify,
                propertyName = 'optimizely',
                propertyValue = [];
            if(amplitude){
                for(var i = 0;i< arguments.length;i++){
                    propertyValue.push(arguments[i]);
                }
                propertyValue = propertyValue.join('||');
                identify = new amplitude.Identify();
                identify.append(propertyName,[propertyValue]);
                amplitude.identify(identify);
            }
        }
    };
}(document));
